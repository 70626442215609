import * as React from "react";
import "./Domaines.sass";
import contract from "../images/icons/contract.png";
import company from "../images/icons/company.png";
import hr from "../images/icons/hr.png";
import locaux from "../images/icons/locaux.png";
import debiteurs from "../images/icons/debiteurs.png";
import pi from "../images/icons/pi.png";
import immo from "../images/icons/immo.png";
import constru from "../images/icons/constru.png";
import creanciers from "../images/icons/creanciers.png";
import contentieux from "../images/icons/contentieux.png";
import abos from "../images/icons/abos.png";
import arrow from "../images/arrow_up.png";
import { Link } from "gatsby";

const getImage = (slug) => {
  switch (slug) {
    case "constitution-societes":
      return company;
    case "vos-contrats":
      return contract;
    case "ressources-humaines":
      return hr;
    case "locaux":
      return locaux;
    case "debiteurs":
      return debiteurs;
    case "propriete-intellectuelle":
      return pi;
    case "immobilier":
      return immo;
    case "constructions":
      return constru;
    case "creanciers":
      return creanciers;
    case "contentieux":
      return contentieux;
    case "abonnement":
      return abos;
    default:
      return null;
  }
};

const Domaines = (props) => {
  return (
    <div id="domaines">
      <h5>Services spécialisés</h5>
      <h2>Nos prestations</h2>
      <p>
        Découvrez nos prestations et services spécialisés selon vos besoins
        juridiques.
      </p>
      <div id="domaines-holder">
        {props.domains
          .filter((dom) => dom.slug !== "abonnement")
          .map((card) => {
            return (
              <Link to={"/domaines/" + card.slug}>
                <div className="domaines-card">
                  <img
                    src={getImage(card.slug)}
                    className="icon"
                    alt="card-icon"
                  ></img>
                  <div>
                    <h3>{card.title}</h3>
                    <p>{card.text}</p>
                  </div>
                  <img src={arrow} id="arrow" alt="arrow-icon"></img>
                </div>
              </Link>
            );
          })}
      </div>
    </div>
  );
};

export default Domaines;
