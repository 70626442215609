import * as React from "react";
import "./Hero.sass";
import { Link } from "gatsby";
import { useState, useRef } from "react";
import { useEffect } from "react";
import grid from "../images/black_grid.png";
import { telephonePrest, meetingPrest } from "../helpers";
import arrow from "../images/arrow_white.png";

const Hero = () => {
  const [slide, setSlide] = useState(0);
  const timer = useRef(null);

  const clickButton = () => {
    if (slide === 3) {
      setSlide(0);
      return;
    } else {
      setSlide(slide + 1);
      return;
    }
  };

  const sliderInit = () => {
    if (slide === 3) {
      setSlide(0);
      return;
    } else {
      setSlide(slide + 1);
      return;
    }
  };

  useEffect(() => {
    timer.current = setInterval(sliderInit, 3500);
    return () => clearInterval(timer.current);
  }, [slide]);

  return (
    <div id="hero">
      <div id="text">
        <h1>Vos démarches juridiques en toute simplicité</h1>
        <p>
          <span>Legalify</span> propose des conseils juridiques en ligne et des
          services pour entreprises et particuliers à Genève et en Suisse. Nous
          vous accompagnons et vous conseillons dans vos démarches juridiques et
          administratives et vous mettons en relation avec nos avocats pour des
          conseils personnalisés. Trouvez le service adapté à vos besoins.
        </p>
        <Link
          id="hero-cta"
          to={sliderContent[slide].link}
          state={sliderContent[slide].state}
          className="button"
        >
          {sliderContent[slide].cta}
          <img src={arrow}></img>
        </Link>
      </div>
      <div id="hero-container">
        <img src={grid} id="black-grid" alt="decorating pattern"></img>
        <div
          id="image-slider"
          className={
            slide === 0
              ? "slide1"
              : slide === 1
              ? "slide2"
              : slide === 2
              ? "slide3"
              : "slide4"
          }
        ></div>

        <div id="lower-holder" role="button">
          <div id="cta-holder">
            <Link
              to={sliderContent[slide].link}
              state={sliderContent[slide].state}
            >
              <h2>{sliderContent[slide].title}</h2>
              <p>{sliderContent[slide].desc}</p>
              <h3>{sliderContent[slide].price}</h3>
            </Link>
            <div id="index">
              <div
                className="dot"
                id={slide === 0 ? "active-dot" : ""}
                onClick={() => setSlide(0)}
              ></div>
              <div
                className="dot"
                id={slide === 1 ? "active-dot" : ""}
                onClick={() => setSlide(1)}
              ></div>
              <div
                className="dot"
                id={slide === 2 ? "active-dot" : ""}
                onClick={() => setSlide(2)}
              ></div>
              <div
                className="dot"
                id={slide === 3 ? "active-dot" : ""}
                onClick={() => setSlide(3)}
              ></div>
              <div id="next" onClick={clickButton} role="button">
                →
              </div>
            </div>
          </div>
        </div>
      </div>
      <Link
        id="hero-cta-mobile"
        to={sliderContent[slide].link}
        state={sliderContent[slide].state}
        className="button"
      >
        {sliderContent[slide].cta}
        <img src={arrow}></img>
      </Link>
    </div>
  );
};

const sliderContent = [
  {
    title: "Entretien téléphonique",
    desc: "Prenez rendez-vous avec un avocat pour un entretien téléphonique de 30 à 40 minutes.",
    price: "CHF 100.-",
    cta: "Prendre rendez-vous",
    link: "/checkout",
    state: telephonePrest(),
  },
  {
    title: "Entretien en personne",
    desc: "Prenez rendez-vous avec un avocat pour un entretien en personne de 45 à 60 minutes.",
    price: "CHF 150.-",
    cta: "Prendre rendez-vous",
    link: "/checkout",
    state: meetingPrest(),
  },
  {
    title: "Documents juridiques",
    desc: "Téléchargez les documents juridiques dont vous avez besoin en quelques clics.",
    price: "",
    cta: "Télécharger un document",
    link: "/domaines/vos-contrats/contrats-et-documents",
    state: {},
  },
  {
    title: "Abonnements juridiques",
    desc: "Recevez tous les mois des conseils juridiques en fonction de vos besoins.",
    price: "Dès CHF 500.-",
    cta: "Souscrire un abonnement",
    link: "/domaines/abonnement",
    state: {},
  },
];

export default Hero;
