import * as React from "react";
import "@fontsource/dm-sans";
import "./index.sass";
import Layout from "../components/Layout";
import Hero from "../components/Hero";
import Domaines from "../components/Domaines";
import Simplicite from "../components/Simplicite";
import QuickActions from "../components/QuickActions";
import QuickAbo from "../components/QuickAbo";
import QuickContract from "../components/QuickContract";

import { graphql } from "gatsby";

// markup
const IndexPage = ({ data }) => {
  const [isMobile, setIsMobile] = React.useState(false);

  React.useEffect(() => {
    // Only run if `window` is defined
    if (typeof window !== "undefined") {
      // Set initial state based on the current window width
      setIsMobile(window.innerWidth < 768);

      // Define the resize handler
      const handleResize = () => {
        setIsMobile(window.innerWidth < 768);
      };
      
      // Add resize event listener
      window.addEventListener("resize", handleResize);
      
      // Cleanup listener on unmount
      return () => window.removeEventListener("resize", handleResize);
    }
  }, []);

  return (
    <Layout>
      <div id="indexpage">
        <Hero></Hero>
        {isMobile ? <QuickActions/> : null}
        <Domaines
          domains={data.allDomainsJson.edges.map((e) => {
            return {
              title: e.node.cardTitle,
              text: e.node.cardText,
              slug: e.node.slug,
            };
          })}
        ></Domaines>
        <div id="home-actions">
          {!isMobile ? <QuickActions/> : null}
          <QuickContract></QuickContract>
          <QuickAbo></QuickAbo>
        </div>

        <Simplicite />
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    allDomainsJson {
      edges {
        node {
          slug
          cardTitle
          cardText
        }
      }
    }
  }
`;

export default IndexPage;
