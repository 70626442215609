import * as React from "react";
import "./QuickContract.sass";
import { Link } from "gatsby";
import arrow from "../images/arrow_white.png";
const QuickContract = () => {
  return (
    <div id="contract-action-container">
      <p id="sub">Documentation</p>
      <h3>Contrats et documents juridiques</h3>
      <p id="subtitle">
        Trouvez et téléchargez des contrats et des documents juridiques ou
        administratifs dans notre base de données.
      </p>
      <p id="long">
        Legalify vous propose plusieurs documents juridiques couramment utilisés
        par les entreprises et les particuliers. Vous trouverez parmi nos
        documents des contrats courants en pratique, des documents juridiques
        standards, ainsi que des documents administratifs nécessaires à la
        gestion d'une société. Parcourez notre base de données, sélectionnez le
        document dont vous avez besoin et téléchargez-le.
      </p>
      <Link to="/domaines/vos-contrats/contrats-et-documents">
        Accéder à la base de données <img src={arrow}></img>
      </Link>
    </div>
  );
};

export default QuickContract;
