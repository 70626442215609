import React from "react";
import "./Simplicite.sass";
import simple from "../images/simple.png";

const Simplicite = () => {
  //Colors for svg illustration, can be adjusted via props if desired (or SCSS via :export)
  const black = "#1F1F1F";
  const yella = "#FAFC45";
  return (
    <div className="simplicite">
      <div className="header-row">
        <img alt="working-session" src={simple} />
        <div id="header-container">
          <h5>Legal 2.0</h5>
          <h2>Le droit, en toute simplicité.</h2>
          <p>
            Legalify propose des solutions juridiques pratiques adaptées aux
            besoins de chacun. <br />
            <br />
            Que ce soit pour la création d'une entreprise, la recherche de
            documents contractuels, les conseils juridiques dans plusieurs
            domaines tels que le droit du travail, la propriété intellectuelle,
            le recouvrement de factures, le droit immobilier et les
            constructions ou la représentation en cas de litige, Legalify vous
            fournit son assistance de manière simple et claire. <br />
            <br />
            Notre plateforme est conçue pour que vous trouviez le plus
            rapidement et le plus simplement possible la solution à votre
            problème.
          </p>
        </div>
      </div>
      <div id="illustration-container">
        <svg
          width="983"
          height="132"
          viewBox="0 0 983 132"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect opacity="1" x="849" width="104" height="204" fill={black} />
          <path
            d="M875.877 121.756C873.063 121.756 870.522 121.252 868.254 120.244C865.986 119.194 864.159 117.64 862.773 115.582C861.429 113.524 860.715 110.962 860.631 107.896H865.986C866.028 110.416 866.91 112.6 868.632 114.448C870.354 116.254 872.769 117.157 875.877 117.157C878.985 117.157 881.316 116.296 882.87 114.574C884.466 112.852 885.264 110.773 885.264 108.337C885.264 106.279 884.76 104.599 883.752 103.297C882.786 101.995 881.463 101.029 879.783 100.399C878.145 99.769 876.339 99.454 874.365 99.454H871.089V94.981H874.365C877.221 94.981 879.447 94.33 881.043 93.028C882.681 91.726 883.5 89.899 883.5 87.547C883.5 85.573 882.849 83.956 881.547 82.696C880.287 81.394 878.397 80.743 875.877 80.743C873.441 80.743 871.488 81.478 870.018 82.948C868.548 84.376 867.729 86.182 867.561 88.366H862.206C862.332 85.972 862.962 83.851 864.096 82.003C865.272 80.155 866.868 78.727 868.884 77.719C870.9 76.669 873.231 76.144 875.877 76.144C878.733 76.144 881.106 76.648 882.996 77.656C884.928 78.664 886.377 80.008 887.343 81.688C888.351 83.368 888.855 85.216 888.855 87.232C888.855 89.458 888.246 91.495 887.028 93.343C885.81 95.149 883.983 96.367 881.547 96.997C884.151 97.543 886.314 98.782 888.036 100.714C889.758 102.646 890.619 105.187 890.619 108.337C890.619 110.773 890.052 113.02 888.918 115.078C887.826 117.094 886.188 118.711 884.004 119.929C881.82 121.147 879.111 121.756 875.877 121.756Z"
            fill={yella}
          />
          <rect opacity="1" x="13" width="104" height="204" fill={black} />
          <path
            d="M39.5036 121V82.822L32.3846 84.397V80.743L41.2676 76.9H44.8586V121H39.5036Z"
            fill={yella}
          />
          <rect opacity="1" x="431" width="104" height="204" fill={black} />
          <path
            d="M443.943 121V117.283C446.925 114.931 449.739 112.558 452.385 110.164C455.073 107.728 457.446 105.334 459.504 102.982C461.604 100.63 463.242 98.32 464.418 96.052C465.636 93.784 466.245 91.6 466.245 89.5C466.245 87.946 465.972 86.497 465.426 85.153C464.922 83.809 464.082 82.738 462.906 81.94C461.73 81.1 460.134 80.68 458.118 80.68C456.186 80.68 454.569 81.121 453.267 82.003C451.965 82.843 450.978 83.977 450.306 85.405C449.676 86.833 449.361 88.366 449.361 90.004H444.258C444.258 87.106 444.867 84.628 446.085 82.57C447.303 80.47 448.962 78.874 451.062 77.782C453.162 76.69 455.535 76.144 458.181 76.144C462.087 76.144 465.279 77.257 467.757 79.483C470.277 81.667 471.537 84.943 471.537 89.311C471.537 91.915 470.886 94.498 469.584 97.06C468.282 99.58 466.623 102.037 464.607 104.431C462.591 106.783 460.428 108.988 458.118 111.046C455.85 113.104 453.729 114.952 451.755 116.59H473.049V121H443.943Z"
            fill={yella}
          />
          <line
            opacity="0.85"
            x1="133.5"
            y1="55.5"
            x2="422.5"
            y2="55.5"
            stroke="#979797"
            stroke-linecap="round"
            stroke-linejoin="round"
            strokeDasharray="8 8"
          />
          <line
            opacity="0.85"
            x1="549.5"
            y1="55.5"
            x2="838.5"
            y2="55.5"
            stroke="#979797"
            stroke-linecap="round"
            stroke-linejoin="round"
            strokeDasharray="8 8"
          />
        </svg>
        <div id="image1-text">
          <div className="image-header">Sélection</div>
          <div className="image-description">
            Trouvez les renseignements que vous recherchez ou les services dont
            vous avez besoin parmi les nombreuses prestations proposées sur
            notre plateforme.
          </div>
        </div>
        <div id="image2-text">
          <div className="image-header">Action</div>
          <div className="image-description">
            Commandez votre prestation directement sur notre plateforme ou
            soumettez-nous vos questions ou vos problèmes en nous contactant.
          </div>
        </div>
        <div id="image3-text">
          <div className="image-header">Résolution</div>
          <div className="image-description">
            Trouvez la solution adaptée à vos besoins et les réponses à vos
            questions.
          </div>
        </div>
      </div>
    </div>
  );
};

export default Simplicite;
