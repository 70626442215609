import * as React from "react";
import "./QuickAbo.sass";
import { Link } from "gatsby";
import arrow from "../images/arrow_white.png";
const QuickAbo = () => {
  return (
    <div id="abo-action-container">
      <p id="sub">Conseils continus</p>
      <h3>Abonnements juridiques</h3>
      <p id="subtitle">
        Souscrivez un abonnement mensuel vous donnant droit à des conseils et
        des services juridiques personnalisés.
      </p>
      <p id="long">
        Legalify vous propose des conseils et des services juridiques
        personnalisés sous la forme d'abonnements mensuels. Il vous suffit de
        verser chaque mois le montant de votre abonnement et vous recevrez nos
        conseils et notre assistance, selon vos besoins et vos demandes.
        Différents types d'abonnements sont possibles, dès 500.- francs par
        mois, selon les besoins plus ou moins étendus de chaque utilisateur.
      </p>
      <Link to="/domaines/abonnement">
        Découvrir nos abonnements <img src={arrow}></img>
      </Link>
    </div>
  );
};

export default QuickAbo;
